import * as React from 'react';
import { MainStoreConsumer } from '../../../../stores/MainStore';
import { T } from '../../../../stores/TranslationStore';
import styles from '../../Elements.module.scss';
import TextFieldInput from '../../../inputs/TextFieldInput';
import FormPageInputs from '../FormPageInputs';
import ContactDataInputs from '../ContactDataInputs';
import { RequestType } from '../../../../interfaces/RequestType';
import AttachmentsList from '../../../pages/AttachmentsPage/AttachmentsList';

export default class ReviewDataInputs extends React.Component {
	public render(): JSX.Element {
		return (
			<>
				<h3>
					<T>Step5_Section_01_Headline</T>
				</h3>

				<MainStoreConsumer>
					{(store) => {
						const requestType = store.inputs.inputValues['RequestType'].value;
						return (
							<>
								<p
									dangerouslySetInnerHTML={{
										__html: store.translation.t('Step5_Section_01_Infotext'),
									}}
								></p>
								<div className={styles.fields}>
									<TextFieldInput
										label="Step1_Section_01_SelectCompany"
										internalName="RequestCompany"
										disabled={true}
									/>
									<TextFieldInput
										label="Step5_Section_01_RequestType"
										internalName="RequestType"
										disabled={true}
									/>
								</div>
								<div className={styles.linebreak}></div>

								{requestType === RequestType.sponsoring ? (
									<>
										<h3>
											<T>Step5_Section_02_Sponsoring_Headline</T>
										</h3>
										<p
											dangerouslySetInnerHTML={{
												__html: store.translation.t(
													'Step5_Section_02_Sponsoring_Infotext'
												),
											}}
										></p>
									</>
								) : (
									<>
										<h3>
											<T>Step5_Section_02_Donation_Headline</T>
										</h3>
										<p
											dangerouslySetInnerHTML={{
												__html: store.translation.t(
													'Step5_Section_02_Donation_Infotext'
												),
											}}
										></p>
									</>
								)}
							</>
						);
					}}
				</MainStoreConsumer>

				<FormPageInputs disabled />

				<div className={styles.linebreak}></div>

				<ContactDataInputs disabled isReview />

				<div className={styles.linebreak}></div>

				<AttachmentsList isReview />
			</>
		);
	}
}
