import * as React from 'react';
import { Observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';
import translationStore from './TranslationStore';
import captchaStore from './CaptchaStore';
import inputStore from './InputsStore';
import countries from '../data/countries.json';
import companies from '../data/companies.json';
import { IDropdownData } from '../interfaces/IDropdownData';
import { useNavigate } from 'react-router-dom';
import { Language } from '../interfaces/Language';
import { InfoPageType } from '../interfaces/InfoPageType';
import processBarStore from './ProcessBarStore';
import { AttachmentsStore } from './AttachmentsStore';
import { RequestBody } from '../interfaces/RequestBody';

export class MainStore {
	public readonly translation = translationStore;
	public readonly processBar = processBarStore;
	public readonly captcha = captchaStore;
	public readonly inputs = inputStore;
	public isHomePage: boolean = false;
	public readonly attachments = new AttachmentsStore();
	public isSubmitLoading: boolean = false;
	public isError: boolean = false;
	public prevRequestId: string = '';
	public modalDisplay: boolean = false;

	public countries: IDropdownData[] = [];
	public companies: IDropdownData[] = [];

	public submitForm = (): void => {
		this.setIsSubmitLoading(true);
		const requestData = this.inputs.getRequestBody();

		if (requestData) {
			this.submitFormRequest(requestData);
		} else {
			this.setIsError(true);
			this.setIsSubmitLoading(false);
		}
	};

	constructor() {
		makeAutoObservable<MainStore>(this);

		this.getCountries();
		this.getCompanies();
		this.setRequestLanguage();
		this.setDefaultLanguage();
	}

	private getCountries = () => {
		const result: IDropdownData[] = countries.map((c) => {
			return {
				name: c.name,
			};
		});

		this.setCountries(result);
	};

	private getCompanies = () => {
		const result: IDropdownData[] = companies.map((c) => {
			return {
				name: c.name,
			};
		});

		this.setCompanies(result);
	};

	private setDefaultLanguage = (): void => {
		const browserLanguage = navigator.language;
		browserLanguage.indexOf('en') !== -1
			? this.setLanguage(Language.en)
			: this.setLanguage(Language.de);
	};

	public setLanguage = (language: Language): void => {
		this.translation.saveLanguage(language);
		this.setRequestLanguage();
	};

	private setRequestLanguage = (): void => {
		this.inputs.setRequestLanguage(this.translation.language);
	};

	public setCountries = (countries: IDropdownData[]): void => {
		this.countries = countries;
	};

	public setCompanies = (companies: IDropdownData[]): void => {
		this.companies = companies;
	};

	public setIsHomepage(): void {
		this.isHomePage = true;
	}

	public setModalDisplay(value: boolean): void {
		this.modalDisplay = value;
	}

	public checkInfoPageUrl = (): boolean => {
		const location = window.location.hash.split('/');
		const infoPage =
			location[1] === InfoPageType.imprint ||
			location[1] === InfoPageType.legal ||
			location[1] === InfoPageType.protection;

		return infoPage;
	};

	public handleOneTrustModal = (): void => {
		if (window.OneTrust.ToggleInfoDisplay) {
			window.OneTrust.ToggleInfoDisplay();
		}
	};

	public setIsSubmitLoading = (value: boolean): void => {
		this.isSubmitLoading = value;
	};

	private submitFormRequest = (data: RequestBody): void => {
		const requestId = this.inputs.inputValues['RequestId'].value;
		this.prevRequestId = requestId;

		try {
			fetch(
				`https://func-fsesad-test.azurewebsites.net/api/RegisterNewRequest`,
				{
					method: 'POST',
					body: JSON.stringify(data),
					mode: 'cors',
				}
			).then((result) => {
				if (result.status === 200) {
					const attachments: FormData = this.attachments.getAttachmentsData();
					try {
						fetch(
							`https://func-fsesad-test.azurewebsites.net/api/UploadAttachments/${requestId}`,
							{
								method: 'POST',
								body: attachments,
								mode: 'cors',
							}
						).then((result) => {
							if (result.status === 200) {
								this.attachments.clearAttachments();
							} else {
								this.setIsError(true);
							}
						});
					} catch (error) {
						console.error(`[submitAttachments] ${error}`);
					}
				} else {
					this.setIsError(true);
				}
				this.setIsSubmitLoading(false);
				this.inputs.setInitialFields();
			});
		} catch (error) {
			console.error(`[submitForm] ${error}`);
		}
	};

	private setIsError = (value: boolean): void => {
		this.isError = true;
	};
}

const MainStoreContext = React.createContext<MainStore>(
	null as unknown as MainStore
);

export const MainStoreProvider: React.FC<
	React.PropsWithChildren<{ value: MainStore }>
> = ({ children, value }) => {
	const navigate = useNavigate();
	React.useEffect(() => {
		const infoPage = value.checkInfoPageUrl();

		if (
			!infoPage &&
			!value.inputs.inputValues['RequestType'].value &&
			!value.isHomePage
		) {
			navigate('/');
		}
	});
	return (
		<MainStoreContext.Provider value={value}>
			{children}
		</MainStoreContext.Provider>
	);
};

export const MainStoreConsumer = (props: {
	children: (store: MainStore) => React.ReactNode;
}): JSX.Element => (
	<MainStoreContext.Consumer>
		{(store) => <Observer>{() => <>{props.children(store)}</>}</Observer>}
	</MainStoreContext.Consumer>
);
